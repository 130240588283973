import actions from "./actions"

const initialState = []

const householdStatusReducer = (state=initialState, action) => {
    const {type, data, err} = action
    switch(type){
        case actions.GET_FAKE_STATUS:
            return data
        case actions.GET_ONE_STATUS:
            return [...state, data]
        default:
            return state
    }
}
export default householdStatusReducer
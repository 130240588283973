const actions = {
    // action types
    GET_FAKER_HOUSEHOLD: "get faker households",

    CREATE_HOUSEHOLD: "create household",

    MODIFY_HOUSEHOLD: "modify household",

    DESTROY_HOUSEHOLD: "destroy household",


    // dispatch arguments
    getHouseholds: (fakeHousehold) => ({
        type: actions.GET_FAKER_HOUSEHOLD,
        data: fakeHousehold
    }),

    createHousehold: (household) => ({
        type: actions.CREATE_HOUSEHOLD,
        data: household
    }),

    modifyHousehold: (id, newData) => ({
        type: actions.MODIFY_HOUSEHOLD,
        data: {id, newData}
    }),

    destroyHousehold: (id) => ({
        type: actions.DESTROY_HOUSEHOLD,
        data: id
    })
}

export default actions
import { randomReferees } from "../../fakerdata/personnel";

const initialState = randomReferees(5)

const personnelsReducer = (state=initialState, action) => {
  switch(action.type) {
    default: 
      return state
  }
}

export { personnelsReducer }

import actions from "./actions";

const initialState = {
  stages: JSON.parse(localStorage.getItem("stage")) || [],
  santes: JSON.parse(localStorage.getItem("sante")) || [],
  studyTrip: JSON.parse(localStorage.getItem("studyTrip")) || [],
  casEtudiant : JSON.parse(localStorage.getItem("casEtudiant")) || [],
}

const formulaireReducer = (state=initialState, action) => {
  const {type, data, err} = action
  switch(type){
    case actions.CREATE_STAGE_FORMULAIRE:
      return {...state, stages: data}
    case actions.CREATE_SANTE_FORMULAIRE:
      return {...state, santes: data}
    case actions.CREATE_STUDYTRIP_FORMULAIRE:
      return {...state, studyTrip: data}
    case actions.CREATE_CASETUDIANT_FORMULAIRE:
      return {...state, casEtudiant: data}
    case actions.REMOVE_STAGE_FORMULAIRE:
      return {...state, stages: data}
    case actions.REMOVE_SANTE_FORMULAIRE:
      return {...state, santes: data}
    case actions.REMOVE_STUDYTRIP_FORMULAIRE:
      return {...state, studyTrip: data}
    case actions.REMOVE_CASETUDIANT_FORMULAIRE:
      return {...state, casEtudiant: data}
    case actions.SUBMIT_FORMULAIRE:
      return {...state, stages: [], santes: [], studyTrip: [], casEtudiant: []}
      
    default:
      return state
  }
};
export default formulaireReducer;


const actions = {
    // action type
    GET_FAKE_VAD: "get fake vad",
    GET_VAD_BY_FOYER: "get vad by foyer",
    CREATE_VAD: "create vad",

    // action dispatch args
    getFakeVAD: (fakeVAD) => ({
        type: actions.GET_FAKE_VAD,
        data: fakeVAD
    }),

    getVADbyFoyer: (vad) => ({
        type: actions.GET_VAD_BY_FOYER,
        data: vad
    }),

    createVAD: (vad) => ({
        type: actions.CREATE_VAD,
        data: vad
    })


}
export default actions
const actions = {
    // action type
    GET_FAKE_STATUS: "get fake household vad status",
    GET_ONE_STATUS: "get one fake household status",

    // action dispacth args
    getFakeStatus: (fakeStatus) => ({
        type: actions.GET_FAKE_STATUS,
        data: fakeStatus
    }),

    getOneFakeStatus: (status) => ({
        type: actions.GET_ONE_STATUS,
        data: status
    })
}
export default actions
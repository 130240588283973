const actions = {
    // action types
    GET_FAKER_STUDENTS: "get faker students",

    CREATE_STUDENT: "create student",

    MODIFY_STUDENT: "modify student",

    DESTROY_STUDENT: "destroy student",

    // action dispatch args
    getStudents: (fakeStudents) => ({
        type: actions.GET_FAKER_STUDENTS,
        data: fakeStudents
    }),

    createStudent: (student) => ({
        type: actions.CREATE_STUDENT,
        data: student
    }),

    modifyStudent: (newData) => ({
        type: actions.MODIFY_STUDENT,
        data: newData
    }),

    destroyStudent: (id) => ({
        type: actions.DESTROY_STUDENT,
        data: id
    })
}
export default actions

import { faker } from "@faker-js/faker"


export const randomReferees = (length) => {

    const referees = []

    for(let i=0; i < length; ++i) {
        const name = faker.person.lastName()
        const firstName = faker.person.firstName()
        
        const referee = {
            id: i+1,
            name: name,
            firstName: firstName,
            email: faker.internet.email({
                firstName: firstName, 
                name: name,
                provider: 'promes.mg' 
            }),
            password: faker.internet.password(),
            occupation: "referant",
            department: "SUP",
        }

        referees.push(referee)
    }

    return referees
}
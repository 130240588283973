import actions from "./actions";

const initialState = localStorage.getItem('households') ? JSON.parse(localStorage.getItem('households')) : []

export const householdReducer = (state=initialState, action) => {
  const {type, data, err} =  action

  switch(type){
    
    case actions.GET_FAKER_HOUSEHOLD:
      return data

    case actions.CREATE_HOUSEHOLD:
      return [...state, data]
      
    case actions.MODIFY_HOUSEHOLD:
      return state.map(household => {
        const householdId = parseInt(data.id)
        if(household.id == householdId){
          return {...household, ...data.newData}
        }else{
          return household
        }
      })

    case actions.DESTROY_HOUSEHOLD:
      return state.filter(household => household.id !== parseInt(data))

    default: 
      return state
  }
};


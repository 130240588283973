
const actions = {
    // action type
    CREATE_STAGE_FORMULAIRE: "create stage formulaire",
    CREATE_STUDYTRIP_FORMULAIRE: "create studytrip formulaire",
    CREATE_SANTE_FORMULAIRE: "create sante formulaire",
    CREATE_CASETUDIANT_FORMULAIRE: "create casEtudiant formulaire",
    

    REMOVE_STAGE_FORMULAIRE: "remove stage formulaire",
    REMOVE_STUDYTRIP_FORMULAIRE: "remove studytrip formulaire",
    REMOVE_SANTE_FORMULAIRE: "remove sante formulaire",
    REMOVE_CASETUDIANT_FORMULAIRE: "remove casEtudiant formulaire",

    SUBMIT_FORMULAIRE: "submit formulaire",
    // action dispatch args
    createStageFormulaire: (stage) => ({
        type: actions.CREATE_STAGE_FORMULAIRE,
        data: stage
    }),

    createStudytripFormulaire: (studytrip) => ({
        type: actions.CREATE_STUDYTRIP_FORMULAIRE,
        data: studytrip
    }),

    createSanteFormulaire: (sante) => ({
        type: actions.CREATE_SANTE_FORMULAIRE,
        data: sante
    }),

    createCasEtudiantFormulaire: (casEtudiant) => ({
        type: actions.CREATE_CASETUDIANT_FORMULAIRE,
        data: casEtudiant
    }),

    removeStageFormulaire: (stage) => ({
        type: actions.REMOVE_STAGE_FORMULAIRE,
        data: stage
    }),

    removeStudytripFormulaire: (studytrip) => ({
        type: actions.REMOVE_STUDYTRIP_FORMULAIRE,
        data: studytrip
    }),

    removeSanteFormulaire: (sante) => ({
        type: actions.REMOVE_SANTE_FORMULAIRE,
        data: sante
    }),

    removeCasEtudiantFormulaire: (casEtudiant) => ({
        type: actions.REMOVE_CASETUDIANT_FORMULAIRE,
        data: casEtudiant
    }),

    submitFormulaire: () => ({
        type: actions.SUBMIT_FORMULAIRE
    })

}
export default actions
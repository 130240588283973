import actions from "./actions"
const initialState = []

const activitiesReducer = (state=initialState, action) => {
  const {type, data, err} = action
  switch(type){
    case actions.GET_FAKE_ACTIVITIES:
      return data
    case actions.CREATE_ACTIVITY:
      return [...state, data]
    default:
      return state
  }
}

export default activitiesReducer

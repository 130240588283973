const actions = {
    // action type
    GET_FAKE_ACTIVITIES: "get fake activities",

    CREATE_ACTIVITY: "create activity",

    // action dispatch args
    getFakeActivities: (fakeActivities) => ({
        type: actions.GET_FAKE_ACTIVITIES,
        data: fakeActivities
    }),

    createActivity: (activity) => ({
        type: actions.CREATE_ACTIVITY,
        data: activity
    })
}
export default actions
import actions from "./actions";

const initialState = []

const vadReducer = (state=initialState, action) => {
  const {type, data, err} = action
  switch(type){
    case actions.GET_FAKE_VAD:
      return data
    case actions.GET_VAD_BY_FOYER:
      return data
    case actions.CREATE_VAD:
      return [...state, data]
    default:
      return state
  }
};
export default vadReducer

import actions from "./actions"

const initialState = localStorage.getItem('students') ? JSON.parse(localStorage.getItem('students')) : []

const studentsReducer = (state=initialState, action) => {
  const {type, data, err} = action
  
  switch(type) {
    case actions.GET_FAKER_STUDENTS:
      return data
      
    case actions.CREATE_STUDENT:
        return [...state, data]
    
    case actions.MODIFY_STUDENT:
      return data

    case actions.DESTROY_STUDENT:
      return state.filter(student => student.id !== parseInt(data))

    default:
      return state
  }
}

export { studentsReducer };
